import { Injectable } from '@angular/core';
import { Defaultconfig } from '../shared/models/Defaultconfig';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieStore } from '../shared/helpers/CookieStore';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient, private titleService: Title) { }
  headers: HttpHeaders = new HttpHeaders().set('Authorization', `${'Bearer ' + localStorage.getItem('access_token')}`);

  getDynamicTitle() {
    return 'current title :' + this.titleService.getTitle();
  }

  setDynamicTitle(title: string) {
    this.titleService.setTitle(title);
  }

  getPublicLoginInfo(requestId: string): Observable<any> {
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'public-srv/login/settings/by/requestid/' + requestId, {});
  }

  loginWithEncCreds(data: any): Observable<any> {
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'login-srv/login', data);
  }

  initiateForgetPassword(payload: any): Promise<any> {
    return this.http.post(Defaultconfig.GetAuthBaseURL() + Defaultconfig.VERIFICATION_SRV + 'user/initiate', payload).toPromise();
  }

  resetPasssword(payload: any): Observable<any> {
    return this.http.post(Defaultconfig.GetAuthBaseURL() + Defaultconfig.VERIFICATION_SRV + 'user/data/reset', payload);
  }

  resetVerificationCode(payload: any): Observable<any> {
    return this.http.post(Defaultconfig.GetAuthBaseURL() + Defaultconfig.VERIFICATION_SRV + 'user/verification', payload);
  }

  getRegistrationFields(requestId: string): Observable<any> {
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'public-srv/registration/settings/by/requestid/' + requestId, {});
  }

  register(registrationDetails: any): Observable<any> {
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'users-srv/users/register', registrationDetails);
  }

  resetPassword(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'users-srv/users/changepassword', payload, { headers });
  }
  getnvitedUserInfo(trackId: string): Observable<any> {
    return this.http.get(Defaultconfig.GetAuthBaseURL() + 'users-srv/users/invite/info/public/' + trackId);
  }

  MFAVerifiedList(emailId: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/verified/list', { email: emailId }, { headers });
  }

  MFAEnabledListByAdmin(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.get(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification', { headers });
  }

  getUserInfo(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'users-srv/userinfo', {}, { headers });
  }

  getRegField(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.get(Defaultconfig.GetAuthBaseURL() + 'registrationfields-srv/fields/util/enabled/list', { headers });
  }

  updateUserInfo(payload: any, sub: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.put(Defaultconfig.GetAuthBaseURL() + 'users-srv/users/update/' + sub, payload, { headers });
  }

  getBackupCode(sub: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());

    return this.http.get(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/user-backup-code?sub=' + sub, { headers });
  }

  deleteMfaType(userVerificationSetupId: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.delete(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/deleteUserVerificationSetupById/' + userVerificationSetupId, { headers });
  }

  // Logout from all device
  revokeAllToken(sub: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.get(Defaultconfig.GetAuthBaseURL() + 'token-srv/revokealltoken/' + sub, { headers });
  }

  //To get client id, flow id and logo url -- use logout
  getIdsForLogout(token: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'public-srv/registration/provider/settings/by/token?access_token=' + token, {}, { headers });
  }

  // Logoutinfo
  logout(token: any, post_url: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.get(Defaultconfig.GetAuthBaseURL() + 'token-srv/end_session?access_token_hint=' + token + '&post_logout_redirect_uri=' + post_url, { headers });
  }

  getConsentDetails(track_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'public-srv/precheck/' + `${track_id}`, {}, { headers });
  }

  acceptConsent(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'public-srv/consent/usage/accept', payload, { headers });
  }

  consentContinue(track_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'login-srv/continue/' + `${track_id}`, {}, { headers });
  }

  verifyStatus(track_id: any): Promise<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + `verification-srv/v2/verification/usage/status/${track_id}`, {}, { headers }).toPromise();
  }

  verifiedList(payload: any): Promise<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/verified/list', payload, { headers }).toPromise();
  }

  initiateOTP(payload: any): Promise<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/initiate', payload, { headers }).toPromise();
  }

  validateOTP(payload: any): Promise<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/validate/otp', payload, { headers }).toPromise();
  }

  // MFA apis
  reinitiateOTP(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/reinitiate', payload, { headers });
  }

  reinitiateUsageOTP(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/reinitiate', payload, { headers });
  }

  initiateMFA(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/initiate', payload, { headers });
  }

  getMfaStatus(verification_usage_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/mfastatus/' + verification_usage_id, { headers });
  }

  verifyOtp(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/validate/otp', payload, { headers });
  }

  //User recent activity
  userRecentActivity(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'reports-v2-srv/user-reports/util/useractivity', payload, { headers });
  }

  getProgressiveRegisterInformation(track_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'public-srv/precheck/' + `${track_id}`, {}, { headers });
  }

  progressiveRegister(payload: any, track_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'users-srv/users/register?track_id=' + `${track_id}`, payload, { headers });
  }

  getSocialRegisterInformation(social_track_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'social-login-srv/userinfo/' + `${social_track_id}`, {}, { headers });
  }

  socialRegister(payload: any): Promise<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'users-srv/users/social/register', payload, { headers }).toPromise();
  }

  federationLogin(payload: any, reqest_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'public-srv/login/federation/' + reqest_id, payload, { headers });
  }

  //fido api start
  initiateFidoSetup(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/initiate', payload, { headers });
  }

  registerFidoSetup(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/validate/otp', payload, { headers });
  }

  initiateFidoUsage(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/initiate', payload, { headers });
  }

  registerFidoUsage(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/validate/otp', payload, { headers });
  }

  getUsersDeviceDetails(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.get(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/fido', { headers });
  }

  deleteFidoDevice(device_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.delete(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/fido/' + device_id, { headers });
  }

  //fido api end

  // initiate webcam face recognition
  initiateFaceMFA(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'mfa-srv/register/initate', payload, { headers });
  }

  // upload face
  uploadFace(formData: any, verification_usage_id: any, usage_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    headers.append('content-type', 'multipart/form-data');
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/validate/face?verification_usage_id=' + `${verification_usage_id}` + '&usage_id=' + `${usage_id}`, formData, { headers });
  }

  verifyFace(formData: any, verification_usage_id: any, usage_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    headers.append('content-type', 'multipart/form-data');
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/validate/face?verification_usage_id=' + `${verification_usage_id}` + '&usage_id=' + `${usage_id}`, formData, { headers });
  }

  //scope user consent
  acceptScopeConsent(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.post(Defaultconfig.GetAuthBaseURL() + 'public-srv/consent/usage/accept/scope/user', payload, { headers });
  }

  userConsentActivity(sub: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.get(Defaultconfig.GetAuthBaseURL() + 'scopes-srv/scope/util/find/scope/user/consent/status/' + sub, { headers });
  }

  revokeConsent(sub: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.http.put(Defaultconfig.GetAuthBaseURL() + 'scopes-srv/scope/util/scope/user/consent/status/update/' + sub, payload, { headers });
  }
}
