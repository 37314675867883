import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './services/auth.service';
import { CommunicationService } from './services/communication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ui';
  view_type: any;
  request_id: any;
  client_id: any;
  flow_id: any;
  publicLoginInfo: any;
  titleService: any;

  constructor(private authService: AuthService, private route: ActivatedRoute, private communicationService: CommunicationService) {}

  ngOnInit(): void {
    this.readParams();
  }

  readParams() {
    this.route.queryParams.subscribe(params => {
      this.request_id = params['request_id'];
      if (params['url_to_redirect'] && params['url_to_redirect'].length > 0) {
        const url = new URL(params['url_to_redirect']);
        const searchParams = new URLSearchParams(url?.search);
        this.client_id = searchParams.get('client_id') || '';
        this.request_id = searchParams.get('request_id') || '';
      }
      if (this.request_id) this.getPublicConfig();
      this.communicationService.setQueryParams(params);
    });
  }

  getPublicConfig() {
    this.authService.getPublicLoginInfo(this.request_id).subscribe((data: any) => {
      this.communicationService.setPublicLoginInfo(data.data);
      console.log('--res', data)
    });
  }
}
