import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, of, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CookieStore } from 'src/app/shared/helpers/CookieStore';
import { CommunicationService } from 'src/app/services/communication.service';
import { Defaultconfig } from 'src/app/shared/models/Defaultconfig';
import { NavigationEnd, Router } from '@angular/router';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  token: any;
  resIds: any;
  redirectUrl: any;

  constructor(private authService: AuthService, private communicationService: CommunicationService,
    private router: Router) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.communicationService.queryParams$.subscribe((data: any) => {
          console.log('router=', data);
          this.token = data.access_token;
          // CookieStore.saveBearerToken(this.token);
          localStorage.setItem('access_token', this.token);
        });
      }
    });
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          this.handleAuthError(err);
        }
        return next.handle(req);
      })
    );
  }

  handleAuthError(error: HttpErrorResponse): Observable<any> | any {
    console.log('handleAuthError', error)
    if (error.status === 401) {
      // if (this.token) {
        this.logout();
      // }
      return of(error.message);
    }
    return throwError(error);
  }

  getAllIds() {
    this.authService.getIdsForLogout(this.token).subscribe((res: any) => {
      console.log('Ids', res);
      if (res && res.data) {
        this.resIds = res.data;
        this.redirectUrl = Defaultconfig.getCurrentUrl() + '/login?view_type=login&request_id=' + this.resIds.request_id + '&client_id=' + this.resIds.client_id + '&flow_id=' + this.resIds.flow_id;
        console.log('url', this.redirectUrl);
        localStorage.setItem('post_url', this.redirectUrl);
      }
    });
  }


  logout() {
    this.redirectUrl = localStorage.getItem('post_url')
    this.authService.logout(this.token, encodeURIComponent(this.redirectUrl)).subscribe((res: any) => {
      console.log(res);
    });
    window.open(this.redirectUrl, '_self');
  }

}
